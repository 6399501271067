import React from "react";
import  Topbar  from '../topbar';
import  NewFooter  from '../newFooter';

// Import SCSS file
import './layout.scss';

const Layout = ({children, className, hasTransparentTopBar}) => {

  return(
  <div className={className ? "layout " +  className : "layout "} >
    <Topbar hasTransparentTopBar={hasTransparentTopBar}/>
      {children}
     <NewFooter />
  </div>
  )
}

export default Layout;