 import styled, { css } from 'styled-components';

import {
  TABLET_992, TABLET_740
} from '../../styles/globals/sizes';

import {
  RED_TITLE, WHITE,
} from '../globals/colors';

export const SubTitle = styled.div`
  font-size: 35px;
  line-height: 42px;
  margin-top: 19px;
  font-weight: 600;
  color: ${RED_TITLE};

  @media (max-width: ${TABLET_992}px) {
    text-align: ${({ alignStart }) => (alignStart ? 'start' : 'center')};
  }

  @media (max-width: ${TABLET_740}px) {
    display: ${({ displayNone }) => (displayNone && 'none')};
  }
`;

export const UnderLineTitle = styled.div`
  width: 113px;
  height: 4px;
  margin: 18px 0 24px 0;
  background-color: ${({ isWhite }) => (isWhite ? WHITE : RED_TITLE)};

  @media (max-width: ${TABLET_740}px) {
    display: ${({ displayNone }) => (displayNone && 'none')};
    margin: 18px 0 0 0;
  }
`;

export const BoldText = styled.div`
  font-weight: 600;
  display: contents;
`;

export const List = styled.ul`
  list-style: disc;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '16px' : '0')};

  li::marker {
    font-size: 1.2em;
  }
  li {
    font-size: 16px;
    line-height: 29px;
  }

  ${({ hasTwoColumns }) => (hasTwoColumns && css`
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    list-style-position: inside;
  `)}

  @media (max-width: ${TABLET_740}px) {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }
`;

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  
  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: 90%;
    flex: 1;
  }
`;