import React from "react";
import Popup from 'reactjs-popup';
import { PATHS } from '../../config/urls';
import { Link } from "gatsby"
import 'reactjs-popup/dist/index.css';

// Import SCSS file
import './menu-popup.scss';

import burguerMenu from "../../../static/images/menu/burguer_menu.svg"
import closeCross from '../../../static/images/requestedemopopup/close_cross.svg';
import closeMenu from "../../../static/images/menu/close-menu.svg"
import Menu from "../../../static/images/menu/menu.svg"

type DataProps = {}

export const MenuPopup: React.FC<DataProps> = ({ }) => {

  return (
    <div className="menupopup--container">
      <Popup
        trigger={open => (open ? <button className="menu--cta"><img className="icon" src={closeMenu} alt="Burguer Menu" /></button> :<button className="menu--cta"><img className="icon" src={Menu} alt="Burguer Menu" /></button> )}
        modal
        lockScroll
        nested
      >
        {close => (
          <div className="menu--modal">
            <ul className="link--list">
              <li className="menu--mobile-item"><div className="internal-graper"><Link to={PATHS.INDEX} activeClassName="active" className="menu--mobile-link"> Home </Link></div></li>
              <li className="menu--mobile-item"><div className="internal-graper"><Link to={PATHS.ABOUT_US} activeClassName="active" className="menu--mobile-link"> About Us </Link></div></li>
              <li className="menu--mobile-item"><div className="internal-graper"><Link to={PATHS.SERVICES} activeClassName="active" className="menu--mobile-link"> Services </Link></div></li>
              <li className="menu--mobile-item"><div className="internal-graper"><Link to={PATHS.CONTACT} activeClassName="active" className="menu--mobile-link"> Contact </Link></div></li>
            </ul>
          </div>
        )}
      </Popup>
    </div>
  )
}
