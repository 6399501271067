import { BLOG_URI } from './index'

export const PATHS = {
  INDEX: '/',
  SERVICES: '/services',
  ABOUT_US: '/about-us',
  CONTACT: '/contact-us',
  BLOG: `${BLOG_URI}/sample`,
  PDF_TREATMENT:"/pdf/TreatmentReferralForm.pdf"
}

export const SOCIAL = {
  FACEBOOK: 'https://www.facebook.com/lifehomecarenewjersey',
  INSTAGRAM: 'https://www.instagram.com/lifehomecare.nj/',
  LINKEDIN: 'https://www.linkedin.com/company/lifehomecarenewjersey/',
  HOME_CARE: 'https://www.homecare.life/',
  RIDE: 'https://www.ride.life/',
  DME: 'https://www.mydme.life/'
}
