import styled, { css } from 'styled-components';
import { Link } from "gatsby"

import {
  WHITE, BLACK_GET_IN_TOUCH
} from '../../styles/globals/colors';

import {
  DESKTOP_1200, TABLET_800
} from '../../styles/globals/sizes';

export const FooterContainer = styled.div`
  background-color: ${BLACK_GET_IN_TOUCH};
  padding: 30px 12% 30px 12%;
  width: 100%;

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 30px 2% 30px 2%;
  }

  @media (max-width: 880px) {
    padding: 30px 0% 30px 0%;
  }

  @media (max-width: ${TABLET_800}px) {
    padding: 30px 0 0 0;
  }

`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const LogosDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoAndSocialMedia = styled.div`
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 21px;

  @media (max-width: ${TABLET_800}px) {
    width: 40%;
    justify-content: space-between;
  }

  @media (max-width: 380px) {
    margin-left: 7px;
  }
`;

export const SocialMediaIcon = styled.img`
  width: 23px;
  height: 23px;
  margin-right: 11px;

  @media (max-width: ${TABLET_800}px) {
    width: 31px;
    height: 31px;
  }

  @media (max-width: 380px) {
    margin-right: 7px;
  }
`;

export const Logo = styled.img`
  width: 135px;
  height: 65px;
`;

export const OtherPages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 37px;
  
  @media (max-width: ${TABLET_800}px) {
    justify-content: center;
  }
`;

export const OtherPagesLogo = styled.img`
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft && '37px')};
`;

export const DmeLogo = styled.img`
  margin-left: 37px;
  width: 68px;
  height: 32px;
`;

export const ItemsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 15%;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-left: 2%;
    justify-content: center;
  }

  @media (max-width: 880px) {
    margin-left: 0%;
  }

  @media (max-width: ${TABLET_800}px) {
    justify-content: center;
    margin-top: 25px;
  }
`;

export const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ displayInTablet }) => (displayInTablet ? '25%' : '20%')};

  @media (max-width: ${DESKTOP_1200}px) {
    margin-right: 10px;
  }

  @media (max-width: ${TABLET_800}px) {
    display: ${({ displayInTablet }) => (displayInTablet ? 'flex' : 'none')};
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${WHITE};
`;

export const Text = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${WHITE};
  font-weight: 300;
  
  @media (max-width: 880px) {
    font-size: 10px;
  }

  @media (max-width: 740px) {
    font-size: 15px;
  }
`;

export const ListItem = styled.li`
  font-weight: 300;
  font-size: 15px;
  line-height: 32px;
  color: ${WHITE};
`;

export const CustomLink = styled(Link)`
  color: ${WHITE};
  line-height: 2px;
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  a {
    display: flex;
  }
`;

export const PDFRedirect = styled.a`
  display: block;
  line-height: 1.3;
  color: ${WHITE};
`;

export const ContactIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 11px;

  ${({ isBigger }) => (isBigger
      && css`
      width: 22px;
      height: 22px;
      color: white;
      fill: green;
    ` 
    )}
`;

export const Disclaimer = styled.p`
  width: 100%;
  max-width: 1009px;
  margin: 0 auto;
  padding-top: 10px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${WHITE};

  @media (max-width: ${TABLET_800}px) {
    padding: 0 0 10px;
  }
  @media (max-width: 740px) {
    font-size: 14px;
  }
`;