import React, {useEffect} from "react";
import { PATHS, SOCIAL } from '../../config/urls';

import Logo_White from "../../../static/images/menu/logo-white.svg"

import Logo_Face from "../../images/icon-face.svg"
import Logo_LinkedIn from "../../images/icon-linkedin.svg"
import Logo_Instagram from "../../images/icon-instagram.svg"
import Icon_Email from "../../images/icon-email.svg"
import Icon_Phone from "../../images/icon-phone.svg"
import Icon_Fax from "../../images/fax.svg"

import LogoHomeCare from "../../images/Life-HomeCare.svg"
import LogoRide from "../../images/Life-Ride.svg"
import DME from "../../images/Life-DME.svg"

import AOS from 'aos';

import {
  InternalWrapper
} from '../../styles/globals/styles.jsx';

import {
  FooterContainer, Logo, SectionDiv, Title, Text,
  ItemsDiv, ListItem, LogosDiv, CustomLink, ContactDiv, SocialMedia,
  SocialMediaIcon, ContactIcon, FooterContent,OtherPages, OtherPagesLogo,
  LogoAndSocialMedia, DmeLogo, PDFRedirect,Disclaimer
} from './styles';


const NewFooter = () => {

  useEffect(() => {
    AOS.init({
     
      duration : 2000,
      once: true
    });
  }, [], AOS);

  return(
    <FooterContainer>
      <InternalWrapper>
        <FooterContent>
          <LogosDiv>
            <LogoAndSocialMedia>
              <Logo src={Logo_White} alt="Logo | Sample" />
              <SocialMedia>
                <a href={SOCIAL.FACEBOOK} target="__blank">
                  <SocialMediaIcon src={Logo_Face} alt="Logo | Sample" />
                </a>
                <a href={SOCIAL.LINKEDIN} target="__blank">
                  <SocialMediaIcon src={Logo_LinkedIn} alt="Logo | Sample" />
                </a>
                <a href={SOCIAL.INSTAGRAM} target="__blank">
                  <SocialMediaIcon  src={Logo_Instagram} alt="Logo | Sample" />
                </a>
              </SocialMedia>
            </LogoAndSocialMedia>
              <OtherPages>
                <a href={SOCIAL.HOME_CARE} target="__blank">
                  <OtherPagesLogo src={LogoHomeCare}/>
                </a>
                <a href={SOCIAL.RIDE} target="__blank">
                  <OtherPagesLogo hasMarginLeft src={LogoRide}/>
                </a>
                <a href={SOCIAL.DME} target="__blank">
                  <DmeLogo src={DME}/>      
                </a>
              </OtherPages>
          </LogosDiv>
          <ItemsDiv>
            <SectionDiv isBiggerTablet>
              <Title>
                ABOUT US
              </Title>
              <Text>
                Life Home Therapy’s mission is to rehabilitate lives by providing excellent care with compassion and respect.
              </Text>
            </SectionDiv>
            <SectionDiv>
              <Title>
                QUICK LINKS
              </Title>
              <ul className="unstyled">
                <ListItem>
                  <CustomLink to={PATHS.INDEX}>Home</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink to={PATHS.ABOUT_US}>About Us</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink to={PATHS.SERVICES}>Services</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink to={PATHS.CONTACT}>Contact Us</CustomLink>
                </ListItem>
                <ListItem>
                  <PDFRedirect target="__blank" href={PATHS.PDF_TREATMENT}>Therapy Treatment Referral Form</PDFRedirect>
                </ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv isBigger={true}>
              <Title >
                COVERAGE AREA
              </Title>
              <ul>
                <ListItem>
                  Essex
                </ListItem>
                <ListItem>
                  Morris
                </ListItem>
                <ListItem>
                  Union
                </ListItem>
                <ListItem>
                  Somerset
                </ListItem>
                <ListItem>
                  Passaic
                </ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv displayInTablet>
              <Title>
                CONTACT
              </Title>
              <ContactDiv>
                <a href="tel:(973) 775-9626">
                  <ContactIcon src={Icon_Phone} />
                  <Text>
                    (973) 775-9626
                  </Text>
                </a>
              </ContactDiv>
              <ContactDiv>
              <a href="fax:(973)-435-2571">
                  <ContactIcon isBigger src={Icon_Fax} />
                  <Text>
                    (973) 435-2571
                  </Text>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a href="mailto:hello@hometherapy.life">
                  <ContactIcon src={Icon_Email} />
                  <Text>
                    hello@hometherapy.life
                  </Text>
                </a>
              </ContactDiv>
            </SectionDiv>
          </ItemsDiv>
        </FooterContent>
        <FooterContent>
          <Disclaimer>
            Life Ride, Life Home Care, Life DME, Life Home Therapy, Life Hospice, Life Health Group logos are all Registered in U.S. Patent and Trademark Oﬃce
          </Disclaimer>
        </FooterContent>
      </InternalWrapper>
    </FooterContainer>
  )
}

export default NewFooter;
