export const BLACK = '#000';
export const BLACK_GET_IN_TOUCH= '#333333'

export const RED_TITLE = '#EC3E2C'
export const RED_NEW_TITLE = '#ED1F10'

export const WHITE = '#FFFFFF' 
export const WHITE_CARDS = '#F7F7F7 ' 

export const GRAY_HOVER = '#3a3a3a'

export const GRAY_UNDER_TITLE = '#808285'

export const GRAY_SECTION = '#EBEBEB'

export const GRAY_LINES = '#E8E8E8'
export const GRAY_LINES_SERVICES = '#EBEBEB'
export const GRAY_CARD = '#F7F7F7'

export const BLACK_TEXT = '#333333'
export const BLACK_NEW_TEXT = '#444444'