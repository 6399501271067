import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"
import { PATHS } from '../../config/urls';
import { MenuPopup } from '../menu-popup';

// Import SCSS file
import './topbar.scss';

  
// Import images
import Brand from "../../../static/images/menu/brand.svg"

const Topbar = ({hasTransparentTopBar}) => { 
  const [showBackground, setBackground] = useState(false);

  useEffect(() => {
    !!hasTransparentTopBar &&
    window.addEventListener('scroll', function () {
        let scrollpos = window.scrollY;
      if (scrollpos > 90) {
        setBackground(true)
      } else {
          setBackground(false)
      }
    });
}, [])

  return(
    <nav className={showBackground || !hasTransparentTopBar ? "menu--container backgroundWhite" :"menu--container"}>
      <div className="menu--desktop">
        <div className="container">
            <Link to={PATHS.INDEX} className="menu--desktop-brand"><img src={Brand} alt="Logo | Sample" /></Link>
          <ul className="menu--desktop-list">
            <li className="menu--desktop-item"><Link to={PATHS.INDEX} activeClassName="active" className="menu--desktop-link">Home</Link></li>
            <li className="menu--desktop-item"><Link to={PATHS.ABOUT_US} activeClassName="active" className="menu--desktop-link">About Us</Link></li>
            <li className="menu--desktop-item"><Link to={PATHS.SERVICES} activeClassName="active" className="menu--desktop-link">Services</Link></li>
            <li className="menu--desktop-item"><Link to={PATHS.CONTACT} activeClassName="active" className="menu--desktop-link">Contact</Link></li>
          </ul>
        </div>
      </div>

      <div className="menu--mobile">
        <div className="container">
            <a href={PATHS.INDEX} className="menu--desktop-brand"><img src={Brand} alt="Logo | Sample" /></a>
            {/* <a className="menu--desktop-icon"><img src={closeMenu} alt="Logo | Sample" /></a> */}
          <div className="menu--mobile-button">
            <MenuPopup />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Topbar;
